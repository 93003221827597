import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { PatientPage, SEO } from "components";
import { AveedFinder, PatientSavingsCallout } from "components/shared/callouts";
import imgHero from "images/hero-smiley-dude.png";
import imgShots from "images/5-shots.svg";
import "./index.scss";

const pageDescription = "Learn about AVEED® (testosterone undecanoate) injection CIII. See Safety Information, including Boxed Warning, benefits and risks, and full Prescribing Information. ";


const IndexPage = () => {
  return (
    <PatientPage pageClassName="homepage">
      <SEO pageTitle="Patient Home" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <div className="hero">
          <div className="smiley-dude-block">
            <img src={imgHero} alt="Aveed Hero" />
            <span className="txt-not-actual hide-md">
              Not an actual patient.
            </span>
          </div>
          <div className="right-other-block">
            <div className="top-white-block">
              <p>
                Ask your doctor about{" "}
                <span className="txt-long-acting">LONG-ACTING</span> AVEED
                <sup>&reg;</sup>
              </p>
            </div>
            <div className="bottom-non-white-block">
              <p className="txt-injection">
                AVEED<sup>&reg;</sup> TESTOSTERONE INJECTION
              </p>
              <div className="gradient-block">
                <img src={imgShots} alt="5 shots text" />
              </div>
              <div className="btn-block">
                <a href="/why-aveed/" className="btn-learn-more">
                  Learn More About AVEED<sup>&reg;</sup>
                </a>
              </div>
            </div>
            <span className="txt-not-actual show-md">
              Not an actual patient.
            </span>
          </div>
        </div>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <AveedFinder />
          </Col>
          <Col xs={12} md={6}>
            <PatientSavingsCallout />
          </Col>
        </Row>
      </div>
    </PatientPage>
  );
};

export default IndexPage;
